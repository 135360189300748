.order-item-container {
  padding-bottom: 1rem;
  border-bottom: 1px solid #fff;
  padding-top: 8px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 80px;
}

.order-item-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 80px;
}
.order-item-left h4 {
  margin: 0;
}
.order-item-left p {
  margin: 0;
  font-size: 0.9rem;
  color: #b7b7b7 !important;
  display: flex;
  align-items: center;
}

.order-item-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 80px;
}

.order-item-right h3 {
  font-size: 1.5rem;
}

.order-item-right h3 > span {
  font-size: 0.8rem;
  margin-right: 4px;
  color: #b7b7b7;
}

.in-app-games-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}

.in-app-game-item {
  flex-basis: calc(33.33% - 8px);
  margin-bottom: 12px;
}

.in-app-game-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.in-app-game-thumb {
  height: 100%;
  object-fit: cover;
  width: 100%;
  min-height: 100px;
}

.in-app-game-item .badge-shadow {
  border: 1px solid #895700;
}

.promo-item {
  flex: 0 0 auto;
  width: 340px; /* Adjust this value to set the width of each item */
  margin-right: 10px; /* Adjust the spacing between items */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-size: 24px;
  box-shadow: 0 8px 8px 8px rgba(0, 0, 0, 0.3);
  background: #232323;
  border-radius: 1rem;
  font-weight: bold;
}

.promo-item img {
  width: 100%;
  height: 140px;
  border-radius: 1rem;
  object-fit: cover;
}

.promo-item .promo-content {
  padding: 1rem;
  width: 100%;
}

.promo-item p {
  font-size: 1rem;
  color: white;
  text-align: center;
}

.promo-content .promo-context {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  margin-top: 8px;
}

.promo-context .ant-btn {
  width: 100px;
  font-size: .9rem;
  overflow: hidden;
  word-wrap: break-word; /* Allow text to wrap to the next line */
  white-space: normal; /* Ensure white space is preserved */
  height: auto; /* Let the height adjust based on content */
  background-color: #007bff; /* Button background color */
  color: #fff; /* Button text color */
  border: none; /* Remove button border */
  cursor: pointer;
  line-height: 1.5rem;
}

.promo-item .date {
  font-size: 0.8rem;
  color: white;
}

.promo-item .date-counter-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 1rem;
}
.rtp_rate_bar .progress-bar{
  padding: 0 12px 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}