/* Promo2 */

.promotion-item.promotion2{
    position: relative;
    min-height: 150px;
  }
  .promotion-item.promotion2 img{
    width: 100%;
  }
  .promotion-item.promotion2 .button-container{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }
  .promotion-item.promotion2 .button-container .promotion-label{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .promotion-item.promotion2 .button-container .promotion-label h2,
  .promotion-item.promotion2 .button-container .promotion-label h3{
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }
  .promotion-item.promotion2 .button-container .promotion-label h3 span{
    color: #ACABAA;
    font-weight: 400;
  }
  .promotion-item.promotion2 .click-for-more-info-button{
    padding: 7px 10px;
    color: #000;
    font-size: 10px;
    border: none;
    border-radius: 5px;
  }
  .promotion-info{
    border: 1px solid #0C0C0C;
    border-radius: 0 0 5px 5px;
    position: relative;
    padding: 35px;
    background-color: #050505;
  }
  .promotion-info .promotion-info-title {
    font-size: 28px;
  }
  
  .promotion-info .close-button{
    color: #050505;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    border-radius: 50%;
    margin: 7px;
    padding: 8px;
    font-size: 30px;
    line-height: .5;
  }
  
  /* Layout Card */
  
  .layout-card {
    background-color: #242424;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  
  /* Deposit 2 */
  
  .deposit2-container, .withdraw2-container {
    /* color: #fff; */
    min-height: 100vh;
    /* background: #151515; */
  }
  
  .deposit2-container label, .withdraw2-container label {
    /* color: #fff; */
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .deposit2-container .tab-menu-container, .withdraw2-container .tab-menu-container {
    line-height: 1.428571429;
    /* color: #858585; */
    box-sizing: border-box;
    display: flex;
    font-size: 12px;
    padding: 4px;
    margin-bottom: 10px;
    /* background-color: #000; */
  }
  
  .deposit2-container .tab-menu-container a, .withdraw2-container .tab-menu-container a {
    line-height: 1.428571429;
    font-size: 12px;
    box-sizing: border-box;
    transition: all .3s ease;
    text-decoration: none;
    outline: none;
    flex: 1;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    overflow: hidden;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    /* border-right: 1px solid #000; */
    /* color: #999; */
  }
  
  /* .deposit2-container .tab-menu-container a[data-active="true"], .withdraw2-container .tab-menu-container a[data-active="true"] {
    background-color: #cf7300;
    color: #ffcf77;
  } */
  
  .payment-method-selection {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .payment-method-selection input[type="radio"] {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
  }
  
  .payment-method-selection label {
    flex-basis: calc((100% - 5px*3)/4);
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    /* background-color: #cbcbcb; */
    box-shadow: 0 3px 5px rgba(0,0,0,.2);
  }
  
  .payment-method-selection input[type="radio"]+label {
    /* color: #000; */
  }
  
  .payment-method-selection input[type="radio"]:checked+label {
    /* background-color: #ffab00; */
    /* background-image: linear-gradient(to bottom,#ffab00 0%,#ff7800 100%); */
    /* color: #fff; */
  }
  
  .payment-method-selection input[type="radio"]+label img {
    filter: brightness(0) invert(0.15);
  }
  .payment-method-selection input[type="radio"]:checked+label img {
    filter: brightness(0) invert(1);
  }
  
  .payment-method-selection label img {
    width: 25px;
  }
  
  .layout2 input[type="text"], .layout2 input[type="text"] {
    /* color: #fff; */
    /* background-color: #000; */
    border: 0;
  }
  
  .deposit2-container .amount-container .real-deposit-amount, .withdraw2-container .amount-container .real-deposit-amount {
    font-size: 24px;
    line-height: 1;
    margin-top: 20px;
  }
  
  .deposit2-container .amount-container .real-deposit-amount:before, .withdraw2-container .amount-container .real-deposit-amount:before {
    content: attr(data-title);
    display: block;
    font-size: 14px;
    margin: 7px 0;
  }
  
  .deposit2-container .to-account-label-container, .withdraw2-container .to-account-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
 .layout2 select {
    /* background: #171717; */
    border: none;
    /* color: #838383; */
    text-align: left;
    appearance: auto;
    font-size: 12px !important;
    padding: 6px 12px;
    height: 34px;
    margin-bottom: 15px;
  }
  
  .deposit2-container .bank-info, .withdrawal2-container .bank-info {
    /* background-color: #333; */
    /* color: #999; */
  }
  
  .bank-info {
    background: linear-gradient(to bottom,#141414 0%,#242424 100%);
    padding: 15px 10px;
    border-radius: 5px;
  }
  
  .bank-info [data-bank-info="header"] {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  
  .bank-info [data-bank-info="header"] img {
    height: 20px;
    width: auto;
  }
  
  .bank-info [data-bank-info="details"] {
    margin-top: 10px;
    overflow: hidden;
  }
  
  /* .bank-info h1, .bank-info h2, .bank-info h4, .bank-info [data-bank-info="details"] span {
    color: #fff;
  } */
  .bank-info h2 {
    font-size: 20px;
    letter-spacing: 5px;
  }
  .bank-info h1, .bank-info h2, .bank-info h3 {
    text-transform: uppercase;
  }
  .bank-info h1, .bank-info h2, .bank-info h3, .bank-info h4 {
    margin: 0;
    word-break: break-all;
  }
  
  .bank-info [data-bank-info="actions"] {
    display: flex;
    justify-content: flex-end;
  }
  
  .deposit2-container .copy-bank-account-button:not(:first-child), .withdraw2-container .copy-bank-account-button:not(:first-child) {
    margin-left: 5px;
  }
  
  .bank-info [data-bank-info="actions"] button {
    font-size: 12px;
    text-transform: uppercase;
  }
  
  .deposit2-container .copy-bank-account-button, .withdraw2-container .copy-bank-account-button {
    background: none;
    border: none;
    padding: 0;
    /* color: #ff9200; */
    font-size: 12px;
  }
  
  .deposit2-container .glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
  }
  
  .deposit2-container .glyphicon-file:before {
    content: "\e022";
  }
  .reciept-file {
    padding: 6px 12px;
    /* background: #171717; */
    /* border: 1px solid #171717; */
    /* color: #838383; */
    text-align: left;
  }
  
  .deposit2-container input[type="file"], .withdraw2-container input[type="file"]  {
    background: none;
    border: none;
    /* color: #838383; */
    text-align: left;
    font-size: 10px !important;
    appearance: none;
  }
  
  .layout2 .secondary-button {
    /* background-color: #ff9806; */
    border-radius: 5px;
    border: none;
    /* color: #fff; */
    text-transform: uppercase;
    width: 100%;
    padding: 15px 10px;
    margin-top: 10px;
  }
  
  .menu-sidebar-languages {
    overflow: hidden !important;
    overflow-y: hidden !important;
  }
  
  /* History Tabs */
  
  .reporting-nav-bar {
    line-height: 1.428571429;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid #2d3d8c; */
    border-radius: 5px;
    font-size: 13px;
    overflow: hidden;
    margin-bottom: 15px;
    /* border-color: #d77f03; */
    background-color: transparent;
    /* color: #fff; */
  }
  
  .reporting-nav-bar a {
    line-height: 1.428571429;
    font-size: 13px;
    box-sizing: border-box;
    transition: all .3s ease;
    outline: none;
    text-decoration: none;
    flex-basis: calc(100%/3);
    padding: 10px 0;
    text-align: center;
    color: inherit;
  }

  /* Profile page */

.profile-page2 .tab-menu-background-container{
    padding: 8px 20px;
}
.profile-page2 .tab-menu-container{
    font-size: 12px;
    padding: 4px;
}

.profile-page2 .tab-menu-container a:not(:last-child){
    border-right: 1px solid #000;
}
.profile-page2 .tab-menu-container a[data-active="true"]{
    background-color: #cf7300;
    color: white;
}
.profile-page2 .tab-menu-container[data-style="vertical"] a{
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    line-height: 1;
    font-size: 11px;
}
.profile-page2 .tab-menu-container a{
    flex: 1;
    text-transform: uppercase;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    overflow: hidden;
}
.profile-page2 .tab-menu-container a img{
    height: 25px;
    width: 25px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}
.profile-page2 .standard-form-container{
    min-height: 500px;
    padding: 40px 15px 20px;
}
.profile-page2 .acc_container,.profile-page2 .pswd_container{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.profile-page2 .acc_container:before,.profile-page2 .pswd_container::before, .profile-page2 .acc_container:after, .profile-page2 .pswd_container::after{
    display: table;
    content: " ";
}
.standard-form-title{
    text-align: center;
    margin-bottom: 15px;
    padding: 10px 0;
    position: relative;
}
.standard-form-title:before, .standard-form-title:after{
    width: 30px;
}
.standard-form-title:before{
    right: 100%;
}
.standard-form-title:after{
    left: 100%;
}
.standard-form-title:before, .standard-form-title:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: inherit;
}
.profile-page2 .standard-content-info .standard-content-block{
    padding: 10px;
    margin-bottom: 10px;
}
.profile-page2 .standard-content-info table{
    background: transparent;
    border: none;
    vertical-align: middle;
    width: 100%;
}
.profile-page2 .profile-summary-table th{
    text-align: right;
    padding: 8px;
    border: none;
}
.profile-page2 .profile-summary-table td{
    border: none;
}


.layout2 .standard-required-message {
  color: #f00;
  display: block;
  margin-top: 5px;
  font-size: 12px;
}

.layout2 .banking-details-header{
    margin-bottom: 5px;
    padding: 5px;
}
.layout2 .banking-details-header a{
    border-radius: 5px;
    padding: 2px 2px 6px 4px;
}
.layout2 .bank-info-container{
    position: relative;
}
.layout2 .bank-info-container .carousel-indicators{
    position: static;
    width: 100%;
    margin: 14px 0;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    padding-left: 0;
    text-align: center;
    list-style: none;
}
.layout2 .bank-info-container .carousel-indicators li{
    display: inline-block;
    width: 17px;
    height: 4px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    border-radius: 0;
    border: none;
}
.layout2 .bank-info-container .carousel-indicators .active{
    background-color: #ff9600;
}
.layout2 .carousel-inner{
    position: relative;
    width: 100%;
    overflow: hidden;
}
.layout2 .carousel-inner>.active{
    display: block;
    left: 0;
}
.layout2 .carousel-inner>.item{
    position: relative;
    transition: .6s ease-in-out left;
}
.layout2 .bank-info-block{
    margin: 0 5px;
    padding: 15px 10px;
    border-radius: 5px;
}
.layout2 .bank-info-block .account-number{
    font-size: 25px;
    letter-spacing: 5px;
}
.layout2 .bank-info-block hr{
    border-top: 1px solid #2e2e2e;
    border-bottom: 1px solid #434343;
    margin: 10px 0;
}
.layout2 .bank-info-block .bank-name{
    text-transform: uppercase;
    font-size: 16px;
}
.profile-page2 .referral-field label{
    text-transform: uppercase;
    font-weight: 100;
}
.profile-page2 .referral-field .referral-code-container{
    display: block;
    font-size: 24px;
    margin-top: 5px;
    padding: 10px;
    overflow-wrap: break-word;
    width: fit-content;
    max-width: 100%;
}


/* change password */
.profile-page2 input {
  border: none;
}
.profile-page2 .pswd_container .form-group{
    margin-bottom: 15px;
}
.profile-page2 .standard-form-container label{
    font-weight: normal;
    font-size: 14px;
}
.profile-page2 .standard-password-field{
    position: relative
}
.profile-page2 .standard-password-field>i{
    color: #999;
    position: absolute;
    top: 35px;
    right: 10px;
    bottom: initial;
    cursor: pointer;
}
.profile-page2 .standard-button-group{
    text-align: center;
    margin-bottom: 15px;
}
.profile-page2 .standard-button-group div{
    cursor: pointer;
    width: 100%;
    padding:10px;
    border-radius: 6px;
    margin-top: 10px;
    outline: none;
}


/* Login */

.layout2 .standard-form-container {
  min-height: 500px;
  padding: 25px 15px 20px;
}

.layout2 .standard-form-container label {
  line-height: 1.428571429;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
  /* color: #fff; */
  font-weight: normal;
}

.layout2 .standard-form-container input[type="text"], .layout2 .standard-form-container input[type="password"]{
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  outline: none;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.428571429;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  border-radius: 0;
  font-size: 12px;
  background: #171717;
  border: 1px solid #171717;
  color: #838383;
  text-align: left;
}

.layout2 .standard-form-container input[type="button"]{
  box-sizing: border-box;
  margin: 0;
  font-size: 12px !important;
  line-height: 1.428571429;
  background-image: none;
  outline: none;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  padding: 15px 10px;
  border-radius: 25px;
  margin-top: 10px;
  background-color: #d77f03;
  border: 0 solid #f0a50e;
  cursor: pointer;
}

.login-panel .alert-danger {
  box-sizing: border-box;
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
  padding: 10px;
  font-size: 15px;
}

.login-panel .standard-button-group {
  text-align: center;
  margin-bottom: 15px;
}

.login-panel .forgot-password-link {
  text-align: center;
  box-sizing: border-box;
  transition: all .3s ease;
  text-decoration: none;
  outline: none;
  font-size: 12px;
  color: #ff9806;
}

.login-panel .register-field {
  color: #fff;
  font-size: 12px;
}

.login-panel .register-field .register-button {
  color: #ff9806;
}
.captcha_input{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.captcha-container{
  display: flex;
  align-items: center;
}
.captcha-container .captcha_text{
  background-color: #abdae6;
  font-size: 20px;
  margin-right: 10px;
  padding: 4px 10px;
}
.captcha_input .captcha-container i{
  font-size: 22px;
  top: 0;
  color: #eb8318;
}
.legacy3 table td{
  padding: 15px 8px 6px 0;
  width: 1%;
  white-space: nowrap;
  vertical-align: top;
}
.legacy3 table td:last-child{
  padding: 6px 0;
  width: 100%;
}
.legacy3{
  font-size: 15px;
}
.legacy3 table p{
  font-size: .8em;
  color: #FFC107;
  padding: 5px 0 0 5px;
}
.legacy3 input{
  border-radius: 4px;
}
.legacy3 .captcha_text{
  background-color: #ff0000;
  border-radius: 4px;
  padding: 9px;
  letter-spacing: 5px;
  font-weight: bold;
}
.legacy3 .verification-code{
  padding: 10px;
    margin-top: 10px;
    overflow: hidden;
    border-radius: 5px;
    font-size: 13px;
    background-color: #37474F;
}
.legacy3 .verification-code p{
  font-size: 13px;
  text-transform: capitalize;
}
.legacy3 .verification-code .get-code{
  float: left;
  width: 120px;
  padding: 9px 0 8px;
  background-image: -webkit-linear-gradient(top,#eab92d,#c79810);
  background-image: linear-gradient(to bottom,#eab92d,#c79810);

}

/* Pyramid table */

.pyramid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  margin-top: 10px;
}

.pyramid-title {
  padding: 0 20px;
  margin-bottom: 10px!important;
  font-size: 16px;
  font-weight: 500;
}

.triangle {
  width: 90%;
  margin: auto;
  height: 428px;
  position: relative;
  /* clip-path: polygon(); */
  clip-path: polygon(50% 140%, 0 0, 100% 0);
}

.triangle div {
  width: 100%;
  height: 35px;
  margin: 0 auto 2px;
  color: #fff;
  line-height: 35px;
  text-align: center;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.standard-required-message {
  color: #f00;
  display: block;
  margin-top: 5px;
  font-size: 12px;
}