.transfer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #ccc;
    padding-top: 12px;
}

.transfer-item .left {
    display: flex;
    align-items: center;
}

.transfer-item .avatar {
    border-radius: 8rem;
    border: 1px soldi white;
    width: 50px;
    height: 50px;
    object-fit: cover;
    background: white;
    padding: 1px;
    margin-right: 8px;
}

.transfer-item h4 {
    font-weight: 400;
}
.transfer-item h5 {
    font-weight: 400;
    text-align: right;
}
.transfer-item p > span {
    font-size: 1rem;
    font-weight: 500;
    color: white;
}
.transfer-item .green {
    color: green;
}
.transfer-item .red {
    color: red;
}