body {
  margin: 0;
  padding: 0;
  /* background-color: #18191a; */
  scroll-behavior: smooth;
  font-weight: 100;
  /* background-image: url("../public/images/signback.svg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#root {
  /* background-image: url(../src/images/ECI88_Background.png); */
  max-width: 500px;
  min-height: 100vh;
  margin: auto;
  /* background-color: #171819; */
}

.card[data-card-height="cover-full"],
.card[data-card-height="cover"] {
  height: calc(100vh - 54px) !important;
}

.card[data-card-height="cover-card"] {
  height: calc(100vh - 200px);
}

#form1a-select {
  border-color: rgba(255, 255, 255, 0.1) !important;
  font-size: 15px;
  color: #fff !important;
}

.custom_play_btn {
  border: 0;
  width: 100px;
  border-radius: 4px;
  padding: 3px 5px;
  margin: 8px 7px 12px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
  background: #10396A;
  border: 2px solid #E6960C;
}

.balance_wrapper {
  font-size: 13px;
  font-family: inherit;
}

/* .balance_wrapper .pbalance {
  padding: 10px 0;
} */

.home_balance {
  overflow: hidden;
}

.links {
  text-align: center;
  margin: 0px;
}


.links div,
.balance_btns a {
  /* height: 200px; */
  /* margin: 0px 5px 15px 10px; */
  max-width: 200px;
  display: inline-block;
  /* padding: 5px 15px; */

  border-radius: 2px;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;

  border-radius: 4px;
}

.balance_btns img {
  max-width: 150px;
}


.balance_btns a:nth-child(3) {
  margin-bottom: 0;
}

.register {
  margin-left: 10px !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

.pbalance {
  display: flex;
  overflow: hidden;
}

.balance_btns {
  float: right;
  padding: 0 0 0 5px;
  display: flex;
  /* flex-direction: column; */
  text-align: center;
  column-gap: 8px;
  justify-content: space-between;
  align-items: flex-end;
}


.balance_btns .btns {
  width: 150px;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.balance_btns .btns img {
  margin-right: 5px;
}

.balance_btns .btns i {
  margin: 4px -23px;
  position: absolute;
  font-size: 18px;
}

.page-title .logo {
  height: 46px;
  width: 100%;
  margin: 5px 10px;
}

.currency_icon img {
  height: 32px;
  margin: 10px 0 10px 10px !important;
}

.btn_green {
  color: #212529;
  /* background-color: #E6960C; */
  border-radius: 4px !important;
  border: 0;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
  font-size: 15px !important;
  line-height: 20px !important;
  padding: 8px 0;
  text-transform: uppercase;
}

.register_btn {
  width: 180px;
  /* background: #E6960C; */
  /* border-color: #E6960C; */
  border-radius: 4px;
}

/* .register_btn:hover {
  width: 150px;
  background: #E6960C;
  border-color: #E6960C;
  border-radius: 4px;
} */

.badge_anima {
  background: #f38120;
  margin: 5px -25px;
  position: absolute !important;
  color: #fff !important;
  line-height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 10px;
  top: 0px;
  right: 40%;
  font-weight: 700 !important;
  font-size: 12px !important;
  opacity: 1 !important;
}

.main_pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}

.noticebar {
  /* background-image: url("../src/images/ECI88_Newsfeed.jpg"); */
  font-size: 14px;
  color: white;
  background-color: #ffc107;
  letter-spacing: 0.5px;
  height: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
  line-height: 1;
  background-size: 100% 100%;
  z-index: 2;
  position: relative;
  overflow: hidden;
  display: flex;
}

:where(.css-1wazalj).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 9px;
}

:where(.css-dev-only-do-not-override-1wazalj).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 9px;
}

:where(.css-1wazalj).ant-tabs-top>.ant-tabs-nav,
:where(.css-1wazalj).ant-tabs-bottom>.ant-tabs-nav,
:where(.css-1wazalj).ant-tabs-top>div>.ant-tabs-nav,
:where(.css-1wazalj).ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0 0 0px 0;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 12px;
}

/* :where(.css-dev-only-do-not-override-1wazalj).ant-tabs .ant-tabs-tab:hover {
  color: #DAA520;
  border: 2px solid #DAA520;
} */

#settings {
  padding: 10px;
  font-size: 18px !important;
  font-weight: 700 !important;

}

.setting_head {
  color: white !important;
  border-bottom: 2px solid;
  padding-bottom: 10px !important;
}

#settings a {
  padding: 15px 0 !important;
  border-bottom: 1px solid;
  color: white;
  display: block;
  font-size: 15px;
}

.color_yellow {
  color: #D4A948 !important;
}

#deposit_cards {
  padding: 15px;
  overflow: hidden;
}

#deposit_cards .pay_menu {
  background: transparent;
  display: block;
  padding: 7px;
  border-radius: 4px;
}

#deposit_cards .pay_menu img {
  width: 100%;
}

.deposit-container .deposit-wrap {
  margin: 5px 0 0 0;
}

.deposit-title {
  /* background-color: black; */
  height: 60px;
  width: 100%;
}

.deposit-texttitle {
  line-height: 60px;
  text-align: center;
  font-size: 20px;
  font-family: Arial;
  font-weight: bold;

}


.deposit-wrap .card-style {
  margin: 10px 0;
  border-radius: 10px;
  border: 2px solid #E6960C;
  padding: 5px;
}

.deposit-wrap .card-style img {
  height: 70px;
  width: 100%;
}

.card-style {
  overflow: hidden;
  border-radius: 22px;
  border: none;
  margin: 100px 0;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 20px 25px -5px, rgba(0, 0, 0, 0.02) 0px 10px 10px -5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url('/images/bank.png'); 123321*/
}

.easypay-background, .ewallet-background, .transfer-background, .surepay-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 160px;
  width: 480px;
  margin-bottom: 5px;
}

/* .easypay-background  {
.surepay-background {
  background-image: url('images/ECI88_surepay.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 160px;
  width: 480px;
  margin-bottom: 5px;
}
.ewallet-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 160px;
  width: 480px;
  margin-bottom: 5px;
} */

.easypay-background {
  background-image: url('images/ECI88_easypay.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 160px;
  width: 480px;
  margin-bottom: 5px;
}

.tng-background {
  /* background-image: url('images/ECI88_tng.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 160px;
  width: 480px;
  margin-bottom: 5px;
}



/* .dqr-background {
  background-image: url('images/ECI88_DQR.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 160px;
  width: 480px;
  margin-bottom: 5px;
} */

.category-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  color: #ffffff;
  margin: 13px;

}

.category-image {
  width: 95px;
  height: 95px;
  margin-Bottom: 2px;

}

.category-game-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 5px;
  color: #ffffff;
  margin: 13px;

}

.category-image-vertical {
  width: 50px;
  height: 50px;
  margin-Bottom: 2px;

}


@media (max-width: 768px) {

  .transfer-background,
  .surepay-background,
  .easypay-background,
  .ewallet-background,
  .tng-background,
  .dqr-background {
    height: 126px;
    width: 380px;
    margin-bottom: 5px;
  }

  .category-game {
    width: 60px;
    height: 54px;
    color: #ffffff;
    margin: 10px;

  }

  .category-image {
    width: 67px;
    height: 70px;
    margin-Bottom: 2px;
  }

  .category-game-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 5px;
    color: #ffffff;
    margin: 13px;
  
  }

 .category-image-vertical {
    width: 35px;
    height: 35px;
    margin-Bottom: 2px;
  
  }

  :where(.css-1wazalj).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 5px;
  }

  :where(.css-dev-only-do-not-override-1wazalj).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 5px;
  }

}

@media only screen and (max-width: 428px) {
  .category-game {
    width: 60px;
    height: 54px;
    color: #ffffff;
    margin: 8px;
  }

  .category-image {
    width: 88px;
    height: 88px;
    margin-bottom: 3px;
  }

  .category-game-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 5px;
    color: #ffffff;
    margin: 13px;
  
  }

  .category-image-vertical {
    width: 35px;
    height: 35px;
    margin-Bottom: 2px;
  
  }
}

@media only screen and (max-width: 414px) {
  .category-game {
    width: 60px;
    height: 54px;
    color: #ffffff;
    margin: 6px;
  }

  .category-image {
    width: 85px;
    height: 85px;
    margin-bottom: 3px;
  }

  .category-game-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 5px;
    color: #ffffff;
    margin: 13px;
  
  }

  .category-image-vertical {
    width: 35px;
    height: 35px;
    margin-Bottom: 2px;
  
  }
}

@media only screen and (max-width: 390px) {
  .category-game {
    width: 60px;
    height: 54px;
    color: #ffffff;
    margin: 5px;
  }

  .category-image {
    width: 80px;
    height: 80px;
    margin-bottom: 3px;
  }

  .category-game-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 5px;
    color: #ffffff;
    margin: 13px;
  
  }

  .category-image-vertical {
    width: 35px;
    height: 35px;
    margin-Bottom: 2px;
  
  }
}

/* Styles for Samsung S8+ */
@media only screen and (max-width: 360px) {

  .transfer-background,
  .surepay-background,
  .easypay-background,
  .ewallet-background,
  .tng-background,
  .dqr-background {
    height: 110px;
    width: 330px;
    margin-bottom: 5px;
  }

  .category-game {
    width: 52px;
    height: 46px;
    color: #ffffff;
    margin: 5px;
  }

  .category-image {
    width: 75px;
    height: 75px;
    margin-bottom: 2px;
  }

  .category-game-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 5px;
    color: #ffffff;
    margin: 13px;
  
  }
  
  .category-image-vertical {
    width: 35px;
    height: 35px;
    margin-Bottom: 2px;
  
  }
}


.ot-custom-icon {
  background-image: url('/images/bank.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.mt-custom-icon {
  background-image: url('/images/transfer.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.dg-custom-icon {
  background-image: url('/images/digi.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.mx-custom-icon {
  background-image: url('/images/maxis.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.cl-custom-icon {
  background-image: url('/images/celcom.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.um-custom-icon {
  background-image: url('/images/umobile.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.gr-custom-icon {
  background-image: url('/images/grab.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bo-custom-icon {
  background-image: url('/images/boost.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.tng-custom-icon {
  background-image: url('/images/tng.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.custom-icon {
  display: flex;
  width: 65%;
  align-items: center;
  justify-content: space-between;
}

.custom-icon i {
  font-size: 22px;
  /* color: #ffcb61; */
  margin-right: 10px;
}

.custom_title {
  margin-top: 0px !important;
  padding: 20px !important;
  padding-top: 20px !important;
}
.header_subline{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
}

.header_subline .referral_icon{
  flex-direction: column;
    width: 100%;
    justify-content: center;
}

/*__________________________________________*/
.footer-license {
  margin-top: -50px;
}

.game-license {
  margin: 10px 0 0;
}

.footer-box-wrapper {
  padding: 12px 0;
}

.footer-box-gaming-wrapper {
  padding-right: 0px !important;
}

.footer-box .title {
  font-family: montserratbold;
  font-size: 0.8rem;
  color: #fff;
  font-weight: bold;
}

.footer-box-gaming {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.icon-curacao {
  display: inline-block;
  width: 6.5rem;
  height: 3.18rem;
  margin-right: 15px;
  background-image: url('/images/gc.webp');
  background-size: 100% 100%;
}

.icon-pagcor {
  display: inline-block;
  width: 110px;
  height: 32px;
  background-image: url('/images/pagcor.webp');
  background-size: 100% 100%;
}

.licenseImg-desc {
  font-size: 11px;
  color: #a5a5a5;
}

.footer-box-certification, .footer-box-payment, .footer-box-responsible {
  flex-direction: row;
  align-items: center;
  line-height: 1px;
}

.footer-box-certification i:first-child, .footer-box-certification i:last-child {
  margin-left: 0px;
}

.footer-box-certification i {
  margin: 5px;
}

.icon-bmm {
  display: inline-block;
  width: 2.45rem;
  height: 0.87rem;
  background-image: url('/images/bnm.webp');
  background-size: 100% 100%;
}

.icon-itechlab {
  display: inline-block;
  width: 1.42rem;
  height: 1.4rem;
  background-image: url('/images/itech.webp');
  background-size: 100% 100%;
}

.icon-global {
  display: inline-block;
  width: 1.62rem;
  height: 1.62rem;
  background-image: url('/images/global.webp');
  background-size: 100% 100%;
}

.icon-payouts {
  display: inline-block;
  width: 4rem;
  height: 1.29rem;
  background-image: url('/images/pay.webp');
  background-size: 100% 100%;
}

.icon-verified {
  display: inline-block;
  width: 4rem;
  height: 1.29rem;
  background-image: url('/images/certification_5.webp');
  background-size: 100% 100%;
}

.footer-box-responsible i {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: 100% 100%;
}

.footer-box-responsible i {
  margin: 4px;
  margin-left: 0;
}

.icon-mbb {
  background-image: url('/images/mbb.webp');
}

.icon-cimb {
  background-image: url('/images/cimb.webp');
}

.icon-hlb {
  background-image: url('/images/hlb.webp');
}

.icon-rhb {
  background-image: url('/images/rhb.webp');
}

.icon-bsn {
  background-image: url('/images/bsn.webp');
}

.icon-pbb {
  background-image: url('/images/pbb.webp');
}

.icon-easypay {
  background-image: url('/images/easypay.webp');
}

.icon-surepay {
  background-image: url('/images/surepay.webp');
}

.icon-duitnow {
  background-image: url('/images/duitnow.webp');
}

.icon-tng {
  background-image: url('/images/tng.webp');
}


.footer-box-security i {
  width: 80px;
  display: inline-block;
  height: 35px;
  background-size: 100% 100%;
}

.icon-iovation {
  background-image: url('/images/iovation.webp');
}

.icon-metrix {
  background-image: url('/images/metrix.webp');
}

.footer-box-payment i {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: 100% 100%;
  border-radius: 10px;
}

.footer-box-payment i {
  margin: 4px;
  margin-left: 0;
}

.icon-rp1 {
  background-image: url('/images/responsible_gaming_1_grey@2x.webp');
}

.icon-rp2 {
  background-image: url('/images/responsible_gaming_2_grey@2x.webp');
}

.icon-rp3 {
  background-image: url('/images/responsible_gaming_3_grey@2x.webp');
}

.footer-license img, .trust-partner img {
  width: 50%;
}

.copy-right {
  border-top: 1px solid #616161;
  border-bottom: 1px solid #616161;
  align-items: center;
  padding-bottom: 3px;
}

@media (max-width: 768px){
.copy-right {
  font-family: montserrat;
  font-size: 0.7rem;
  color: #5b616d;
}
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
}

.copy-right .footer-box-follow {
  float: right;
}

.footer-box-follow i {
  display: inline-block;
  width: 1.29rem;
  height: 1.29rem;
  background-size: 100% 100%;
}

.icon-youtube {
  margin-right: 10px;
  margin-top: 5px;
  background-image: url('/images/youtube.webp');
}

.icon-youtube:hover {
  cursor: pointer;
  margin-top: 5px;
  background-image: url('/images/youtube_active.webp');
}

.icon-facebook {
  margin-right: 10px;
  margin-top: 5px;
  background-image: url('/images/facebook.webp');
}

.icon-facebook:hover {
  cursor: pointer;
  margin-top: 5px;
  background-image: url('/images/facebook_active.webp');
}

.icon-instagram {
  margin-right: 10px;
  margin-top: 5px;
  background-image: url('/images/instagram.webp');
}

.icon-instagram:hover {
  cursor: pointer;
  margin-top: 5px;
  background-image: url('/images/instagram_active.webp');
}

/*__________________________________________*/


.invalid-feedback {
  display: block;
  font-size: 13px;
  font-weight: 500;
  margin-top: 4px;
  color: rgb(255, 107, 107) !important;
}

.valid-feedback {
  display: block;
  color: rgb(255, 107, 107) !important;
}

.position-fixed {
  position: fixed;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.w-screen {
  width: 100vw;
}

.w-45 {
  width: 45px !important;
  height: 45px;
}

.h-screen {
  height: 100vh;
}

.top-0 {
  top: 0px;
}

.left-0 {
  left: 0px;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.zIndex100 {
  z-index: 100;
}

.zIndex0 {
  z-index: 0;
}

.bg-01 {
  background: #00000040 !important;
}

.gap-20 {
  gap: 20px;
}

.font-bold {
  font-weight: bold;
}


.banks-container {
  padding: 1rem;
  border: 1px solid #e4ae60;
  border-radius: 10px;
}

.banks-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.banks-wrap .bank-item {
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 8px;
  padding: 10px;
}

.banks-wrap .bank-item img {
  filter: grayscale(100%);
}

.selected-bank {
  color: #e4ae60 !important;
  font-weight: bold;
}

.selected-bank img {
  filter: grayscale(0) !important;
}

.banks-info {
  margin-top: 30px;
  padding: 1rem;
  border: 1px solid #ced4da;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  color: white !important;
}

.banks-info table {
  width: 100%;
}

.copy-btn {
  background: #4a89dc;
  color: white;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  text-shadow: white;
  padding: 0 5px;
  border-radius: 5px;
}

.avatar-uploader {
  color: white;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.playing-badge {
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: #E6960C;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.category-easy-tech .playing-badge {
  bottom: auto;
  top: 0;
  border-radius: 50%;
  width: 20px;
  left: auto;
  height: 20px
}

.loading-image {
  top: 0;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-radius: 5px;
  background: rgb(0 0 0 / 30%);
  color: white;
}

.text-white {
  color: black !important;
  background-color: white !important;
}

.qr-icon {
  color: grey;
  transition: all .5s;
  background-color: #5D9CEC;
  color: white;
  border-radius: 5px;
  padding: 2px;
  margin-left: 10px;
}

.qr-icon:hover {
  background-color: #10396A;
}

.referral-link {
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
}

.referralLink-copy {
  color: gray;
  cursor: pointer;
  transition: all .5s;
}

.referralLink-copy:hover {
  color: #10396A;
}

.qrcode-username {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  font-weight: 500;
  padding: 10px;
  color: #111;
  font-size: 22px;
  border-radius: 10px;
  pointer-events: none;
}


.balance-container {
  /* border: 2px solid #E6960C; */
  border-radius: 30px;
  overflow: hidden;
  padding: 8px;
  padding-bottom: 0;
  /* box-shadow: 
  0 0 12px rgba(230, 150, 12, 0.8), 
  0 0 12px rgba(230, 150, 12, 0.8) inset;  */
}

.balance-context {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance-welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px 0;
  /* background: rgba(0, 0, 0, 0.7); */
  border: 1px solid #212529;
  border-radius: 15px;
}

.balance-welcome-title {
  font-size: 1rem;
  color: #eee;
  font-weight: 500;
  z-index: 10;
}

.balance-welcome-subtitle {
  color: #ccc;
  font-weight: 500;
  font-size: .85rem;
  margin-top: 0px;
  z-index: 10;
}

.balance-text {
  margin-right: 10px;
  color: white;
}

.btns-container {
  display: flex;
  gap: 10px;
}

/* .balance-container {
  border: 2px solid #E6960C;
  border-radius: 30px;
  align-items: center;
  justify-content: space-between;
} */

/* .balance-container>div:nth-child(1) {
  width: 30%;
}

.balance-container>div:nth-child(2) {
  width: 70%;
} */

.items-center {
  align-items: center;
}

/* .balance-text {
  font-size: 22px;
  color: white;
  font-weight: 500;
} */

.balance-spantext {
  font-size: 14px;
  font-weight: 100;
}

.mt-30 {
  margin-top: 30px;
}

.ml-1 {
  margin-left: 1rem;
}

.p-10 {
  padding: 10px;
}

.rounded-15 {
  border-radius: 15px;
}

.light-bg {
  background-color: #efeef3;
}

.referral-link {
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
}

.qrcode-img {
  width: 500px;
  position: relative;
}

.download-app-info {
  font-size: 15px;
}

.download-app-info .img-wrap {
  margin-bottom: 30px;
  border-radius: 10px;
  border: 4px solid #5d9cec;
  padding: 5px;
  overflow: hidden;
}

.download-app-info .img-wrap div {
  height: 130px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.download-app-info .balance-wrap {
  margin-top: 30px;
  color: white;
  background-color: #5d9cec;
  font-weight: bold;
  text-align: center;
  border-radius: 20px 20px 0 0;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.accountInfo-wrap {
  color: white;
}

.download-app-info .accountInfo-wrap {
  padding: 0 10px;
}

.download-app-info span {
  font-weight: bold;
}



.clipboard-wrap {
  padding: 5px 10px;
  width: 150px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px !important;
}

.download-apk-btn {
  border: 1px solid #4096ff;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  font-size: 12px;
}

.play-btn {
  border: 1px solid #8CC152;
  background-color: #8CC152;
  color: white;
  width: 100%;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  font-size: 15px;
}

.ant-tabs-nav::before {
  border-bottom: none !important;
}

:where(.css-dev-only-do-not-override-1wazalj).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: transparent;
  pointer-events: none;
}

:where(.css-dev-only-do-not-override-1wazalj).ant-tabs-top>.ant-tabs-nav,
:where(.css-dev-only-do-not-override-1wazalj).ant-tabs-bottom>.ant-tabs-nav,
:where(.css-dev-only-do-not-override-1wazalj).ant-tabs-top>div>.ant-tabs-nav,
:where(.css-dev-only-do-not-override-1wazalj).ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0px 0 0px 0;
}


@media (max-width: 767px) {

  .balance-container>div:nth-child(1) {
    width: 100%;
  }

  .balance-container>div:nth-child(2) {
    width: 100%;
  }

  /* .transfer-background,
  .surepay-background,
  .easypay-background,
  .tng-background,
  .dqr-background {

} */
}

.login-image {
  width: 100%;
  position: relative;
  margin-right: 5px;
  max-width: 320px;
}

.register-image {
  width: 100%;
  position: relative;
  max-width: 320px;
  margin-left: "5px"
  /* margin-left: 5px; */
  /* position: relative;
    right: 10px;
    top: 20px; */
}


.header-image {
  position: relative;
  align-items: center;
}



.banner-container {
  display: flex;
}

.login-container {
  display: flex;
}

.balance_game, .balance_credit  {
  /* color: white; */
  font-Size: 20px;
  font-Family: Arial;
  font-weight: bold;
}

/* .balance_credit {
  color: #ffcb61;
  font-Size: 18px;
  font-Family: cursive;
  font-weight: bold;
} */

.banner-container {
  cursor: pointer;
}

.css-13cymwt-control {
  background-color: #fff !important;
  height: 53px !important;
  border-radius: 10px !important;
}

.css-t3ipsp-control {
  background-color: #fff !important;
  height: 53px !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 10px !important;
}

.css-1nmdiq5-menu span {
  color: black !important;
}

.max-withdraw-btn {
  background: #10396A;
  color: #FFF;
  right: 0;
  margin-right: 10px;
  border-radius: 12px;
  padding: 5px 10px;
}

.ant-radio-wrapper span {
  color: white !important;
}

.login-container {
  display: flex;
}

.balance_game {
  color: white;
}

.balance_credit {
  color: #ffcb61;
}

.banner-container {
  cursor: pointer;
}

.bank-acc-item {
  margin: 10px 0;
  border-radius: 10px;
  border: 2px solid #E6960C;
  padding: 10px;
  background-color: #10396A;
}

.text-15 {
  font-size: 15px;
}

.w-20 {
  width: 5rem;
}

.h-16 {
  height: 4rem;
}

.m-10 {
  margin: 10px !important;
}

.text-black {
  color: black !important;
}

.css-b62m3t-container {
  margin: 10px 0 !important;
}

.ant-select p {
  color: black !important;
}

.background_class-center {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

.contact_image {
  position: relative;
  /* width: 460px; */
  height: 125px;
  cursor: pointer;
  margin: 10px;
  width: calc(100% - 30px);
  /* right: 50px; */
}

.bethistory-item {
  background: rgba(255, 255, 255, 0.05) !important;
  color: white !important;
  border-color: white !important;
  border-radius: 12px;
}

.font-white {
  color: white !important;
  background-color: white !important;
}

/* .color-white {
  color: white !important;
} */

/* .bg-txtable {
  background-color: #ffcb61;
  font-weight: bold !important;
} */

/* .border-yellow {
  border-color: #ffcb61 !important;
} */

.rotate-180 {
  transform: rotate(180deg);
}

.text-red {
  color: red;
}

.text-red-500 {
  color: red;
  opacity: 0.8;
}

.text-green {
  color: green;
}

/* .color-white {
  color: white;
} */

.bonus-selector {
  overflow: hidden;
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: 1.5px solid #e4ae60;
}

.bonus-select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  pointer-events: none;
}

.bonus-radio+.bonus-select .bonus-check {
  opacity: 0;
  color: #e4ae60;
  font-weight: 900;
}

.bonus-radio:checked+.bonus-select .bonus-check {
  opacity: 1;
}

.status-approve {
  background-color: rgb(34 197 94);
}

.status-error {
  background-color: #ff9999;
}

.status-reject {
  background-color: rgb(217 70 239);
}

.status-delete {
  background-color: rgb(244 63 94);
}

.status-pending {
  background-color: rgb(234 179 8);
}

.status-p {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.pending-delete-btn {
  background-color: rgb(234 179 8);
  margin-left: 5px;
  border-radius: 5px;
  padding: 2px;
}

.withdraw-img {
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 10px 0;
}
.withdraw-img img{
  width: 300px;
}

.items-center {
  align-items: center;
}

.ewallet-item {
  border: 1px solid #ffcb61;
  background-color: transparent !important;
}

:where(.css-1wazalj).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: transparent;
  background: transparent;
}



/* For devices where tab was disorted */
.home-page-content .ant-,
.home-page-content .ant-tabs>div>.ant-tabs-nav,
.home-page-content .ant-tabs>.ant-tabs-nav {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  display: flex;
}

.home-page-content .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.home-page-content .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}

.home-page-content .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.home-page-content .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: opacity 0.3s;
}
.home-page-content .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar-animated{
  display: none !important;
}

.home-page-content .ant-tabs .ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.home-page-content .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: 0 0 0.25px currentcolor;
}

.home-page-content .ant-tabs .ant-tabs-content-holder {
  border-left: 0;
}

.mui-modal-box {
  width: 100%;
  top: 15%;
  text-align: center;
}

.language-btn:hover{
  background: #ccc;
  color: #fff;
}


/* NoInternet.css */

.no-internet-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.no-internet-content {
  text-align: center;
  padding: 20px;
  background-color: #000; /* Background color for the content */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 300px; /* Adjust the max-width as needed */
  width: 100%;
}

.no-internet-container h1 {
  color: #f1f1f1; /* Dark gray color for the heading */
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.no-internet-container p {
  color: #7d7d7d; /* Slightly lighter gray color for the text */
  font-size: 1.2rem;
}

.min-deposit, .min-withdrawl{
  color: white;
    font-Size: 12px;
    font-Family: Arial;
    font-weight: bold;
}

.request-otp-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 1rem;
  margin-top: 4px;
}

.request-otp-box .input-style {
  margin-bottom: 0 !important;
}


.register-bank-details .input-style {
  position: relative;
}
.register-bank-details.t3 select, .register-bank-details.t3 input {
  width: 100%;
  line-height: 45px;
  height: 45px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  padding: 0px;
  border-radius: 0px;
  background: none;
  box-shadow: none !important;
  outline: 0px !important;
}

.register-bank-details.t3 .input-style input {
  font-size: 20px !important;
  padding-left: 25px;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.register-bank-details.t3 .input-style span {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 5px;
  pointer-events: none;
}

.register-bank-details.t3 input:hover, .register-bank-details.t3 input:focus, .register-bank-details.t3 input:active, .register-bank-details.t3 select:hover, .register-bank-details.t3 select:focus, .register-bank-details.t3 select:active, .register-bank-details.t3 select option, .register-bank-details.t3 textarea:hover, .register-bank-details.t3 textarea:focus, .register-bank-details.t3 textarea:active, .register-bank-details.t3 .btn:hover, .register-bank-details.t3 .btn:focus, .register-bank-details.t3 .btn:active, .register-bank-details.t3 button:hover, .register-bank-details.t3 button:focus, .register-bank-details.t3 button:active {
  outline: none;
  box-shadow: none !important;
  -webkit-appearance: none !important;
  background: none;
}

.register-bank-details.t3 .input-style label {
  font-size: 10px;
}

.auth4-balance-container {
  display: flex;
  flex-direction: row; /* Display the items in a row */
  align-items: center; /* Vertically center the items */
}

.auth4-balance-item {
  margin-right: 20px; /* Add spacing between the items */
}

/* top winnings */
.top-winnings-title {
  padding: 8px;
  padding-bottom: 0;
  margin-top: 8px;
}

.top-winnings_container {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-shrink: 0;
  gap: 10px;
  padding: 10px;
  border: 1px solid #FFC107;
  border-radius: 10px;
  margin-top: 8px;
}

.top-winning {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-winning .top-winning-image_container {
  width: 200px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
}

.top-winning-image_container img {
  width: 100%;
  height: 100%;
}

.top-winning .top-winning-title {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.top-winning-button {
  width: 70%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border-radius: 20px;
  border: none;
}

/* Manual Deposit 2 */
.banks-info-2 table {
  background: #343434;
  border: 1px solid #696969;
  width: 100%;
}
.banks-info-2 table td {
  padding: 5px 10px;
  color: #fff;
  border: 1px solid #696969;
}
.banks-info-2 table td.bank-number-td {
  color: #DCAD23;
}
.bank-slip_container {
  padding: 10px;
  background-color: #FFF2E1;
  border: 1px solid #FFB74D;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.bank-slip_container .ant-upload.ant-upload-select {
  width: max-content;
}
.bank-slip_container label {
  font-size: 15px;
  margin-right: 10px;
  color: #000;
  white-space: nowrap;
}
.bank-slip_container label span {
  margin-right: 10px;
}
.bank-slip_container .upload-file_container {
  display: flex;
  align-items: center;
}
.bank-slip_container .upload-file_container .upload-file {
  background-color: #287AAC;
}