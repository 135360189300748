.tick-counter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.tick-counter-container .c {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
}

.tick-credits {
    display: none !important;
}

.tick {
  display: inline-block;
}

.tick-flip {
  min-width: 17px !important;
  min-height: 26px;
  border-radius: 4px !important;
  border: 1px solid #e6960c;
  box-shadow: 
  0 0 6px rgba(230, 150, 12, 0.8), /* Outer shadow */
  0 0 6px rgba(230, 150, 12, 0.8) inset; /* Inner shadow */
}

.tick-flip-panel {
    background: linear-gradient(to top, #c1c1c1, #fff);
    color: #000 !important;
}

.tick-flip-panel-text-wrapper {
  font-size: 1.1rem;
}

.number-increment-counter {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  overflow: hidden;
}

.digit {
  display: inline-block;
  width: 24px;
  text-align: center;
}

.digit.scroll {
  animation: scroll-animation .3s ease-in-out;
}

@keyframes scroll-animation {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-25%);
  }
  50% {
    transform: translateY(-50%);
  }
  75% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(-100%);
  }
}


.sk-loading-container {
  padding: 0 12px;
}

.sk-auth-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 items in a row */
  gap: 8px;
  margin-top: 8px;
}

.sk-auth-grid .grid-item {
  text-align: center;
}

.sk-table-grid {
  display: grid;
  grid-template-columns: 4fr 1fr; /* 3 items in a row */
  gap: 8px;
  margin-top: 8px;
}

.sk-table-grid .grid-item {
  text-align: left;
}

.loader-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
  width: 130px;
  height: 130px;
}

.loader-container-vertical {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  margin-left: 80px;
  width: 130px;
  height: 130px;
}

.loader {
  width: 120px;
  height: 120px;
  position: absolute;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #FFF;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

@font-face {
  font-family: "advanced_dot_digital7";
  src: url("./advanced_dot_digital-7.woff2") format("woff2"), url("./advanced_dot_digital-7.ttf") format("truetype");}